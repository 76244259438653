<template>
  <div>
    <div class="pageTitle">告警事件</div>
    <div class="borderColor mb-4">
      <div class="w-100">
        <div class="d-flex flex-wrap align-items-center m-2 mb-3">
          <!-- <span v-if="selectValue.name === '開始時間'">
                      <date-picker style="width:auto; margin:5px" placeholder="開始時間" v-model="filterStartTime" valueType="format" format="HH:00:00" type="time"></date-picker>
                      ~
                      <date-picker style="width:auto; margin:5px" placeholder="結束時間" v-model="filterEndTime" valueType="format" format="HH:00:00" type="time"></date-picker>
                      </span> -->
          <div class="inputDiv mr-2">
            <i class="fas fa-search mr-2" />
            <input
              v-model="searchInput"
              type="text"
              class="filterInput"
              :placeholder="'依' + selectValue.name + '搜尋'"
            />
            <span class="focus-border" />
          </div>
          <!-- <input v-if="selectValue.name !== '開始時間'" :placeholder="'依' + selectValue.name + '搜尋'"  style="margin:5px 5px" v-model="searchInput"> -->
          <select v-model="selectValue" class="mr-4">
            <option v-for="(i, index) in filterTitle" :key="index" :value="i">
              {{ i.name }}
            </option>
          </select>

          <span class="font-weight-bold">依區域篩選:&ensp;</span>
          <select v-model="filterSite" class="mr-4">
            <option
              v-for="(i, index) in filterSiteArray"
              :key="index"
              :value="i.value"
            >
              {{ i.name }}
            </option>
          </select>

          <span class="font-weight-bold">
            資料筆數:&ensp;{{ historyFilterSearch.length }}
          </span>
          <button
            type="button"
            class="confirmButton p-1 my-1 ml-auto"
            :class="{ disableButton: loadingData }"
            style="width: 80px"
            @click="getRecentAlarms"
          >
            更新
          </button>
        </div>

        <b-overlay :show="loadingData">
          <div class="otherTableStyle tableWrap">
            <table class="w-100 text-center text-nowrap">
              <tr class="sticky-top">
                <th
                  v-for="(i, index) in thTitle"
                  :key="index"
                  class="sort"
                  :class="{ down: !i.isSort, up: i.isSort }"
                  @click="sortHistoryData(i.sortName, index)"
                >
                  <i
                    v-if="i.name === '案場'"
                    class="fas fa-globe-americas mr-1"
                    style="color: #4294cf"
                  />{{ i.name }}
                </th>
                <th>內容</th>
              </tr>
              <tr v-for="(i, index) in historyFilterSearch" :key="index">
                <td class="cursor-pointer" @click="openMap(i.factoryName)">
                  {{ i.factoryName }}
                </td>
                <td>{{ i.county }}</td>
                <td>{{ i.maintainer }}</td>
                <td>{{ i.which }}</td>
                <td>{{ i.lastAlarmType }}</td>
                <td>{{ i.time }}</td>
                <td :style="`${levelBgColor(i.lastColorLabel)} width: 50px`">
                  {{ i.allAlarms.length }}
                </td>
                <td
                  class="cursor-pointer"
                  @click.stop="getModalData(i.allAlarms, i.factoryName)"
                >
                  ...
                </td>
              </tr>
            </table>
          </div>
        </b-overlay>
      </div>
    </div>
    <b-modal id="settingModal" size="xl" :title="modalTitle" ok-only>
      <div class="otherTableStyle">
        <table class="w-100 text-center">
          <tr class="text-nowrap">
            <th>設備</th>
            <th>屬性</th>
            <th>詳細內容</th>
            <!-- <th>開始時間</th> -->
          </tr>
          <tr v-for="(i, idx) in modalData" :key="idx">
            <td>{{ invOrNot(i.inv) }}</td>
            <td class="text-nowrap">{{ i.alarmType }}</td>
            <td class="text-left text-break">
              {{ i.desc }}
            </td>
            <!-- <td>{{changeTime(i.timestamp)}}</td> -->
          </tr>
        </table>
      </div>
    </b-modal>

    <b-modal id="mapModal" size="xl" title="告警地圖" ok-only>
      <div>
        <GmapMap
          style="height: 600px"
          :center="center"
          :zoom="12"
          :options="{ scrollwheel: true }"
        >
          <InfoWindow
            v-for="(x, index) in markers"
            :key="index"
            :position="x.position"
            :opened="true"
            :draggable="false"
            :options="{
              content: `<span style='${x.backgroundColor}'>${x.factoryName}</span>`,
            }"
          />
        </GmapMap>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { BModal, BOverlay } from 'bootstrap-vue'
import { getHistoryAlarmsApi } from '../api'
import { DateTime } from 'luxon'
import { InfoWindow } from 'vue2-google-maps'
export default {
  name: 'HistoryAlarms',
  components: {
    BModal,
    BOverlay,
    InfoWindow
  },
  data () {
    return {
      center: { lat: 23.789212, lng: 121.468511 },
      markers: [],
      filterSite: [],
      filterStartTime: '',
      filterEndTime: '',
      recentAlarmsData: [],
      rebuildAlarmsData: [],
      modalData: [],
      modalTitle: '',
      thTitle: [
        {
          name: '案場',
          sortName: 'factoryName',
          isSort: true
        },
        {
          name: '縣市',
          sortName: 'county',
          isSort: true
        },
        {
          name: '工程師',
          sortName: 'maintainer',
          isSort: true
        },
        {
          name: '設備',
          sortName: 'which',
          isSort: true
        },
        {
          name: '屬性',
          sortName: 'lastAlarmType',
          isSort: true
        },
        {
          name: '開始時間',
          sortName: 'time',
          isSort: true
        },
        {
          name: '影響',
          sortName: 'allAlarms',
          isSort: true
        }
      ],
      searchInput: '',
      selectValue: {
        name: '案場',
        sortName: 'factoryName',
        isSort: true
      },
      loadingData: false
    }
  },
  computed: {
    ...mapState(['filterSiteArray', 'factoryInfo']),
    filterTitle () {
      return this.thTitle.filter(
        (x) => x.name !== '影響' && x.name !== '開始時間'
      )
    },
    historyFilterSearch () {
      // if (
      //   this.selectValue.name === '開始時間' &&
      //   this.filterStartTime !== '' &&
      //   this.filterEndTime !== ''
      // ) {
      //   if (this.filterSite.length === 0) {
      //     return this.rebuildAlarmsData.filter(
      //       (x) =>
      //         x['time'].slice(-8) >= this.filterStartTime &&
      //         x['time'].slice(-8) <= this.filterEndTime
      //     )
      //   } else {
      //     return this.rebuildAlarmsData.filter(
      //       (x) =>
      //         x['time'].slice(-8) >= this.filterStartTime &&
      //         x['time'].slice(-8) <= this.filterEndTime &&
      //         this.filterSite.includes(x['county'])
      //     )
      //   }
      // }
      if (this.filterSite.length > 0) {
        return this.rebuildAlarmsData.filter(
          (resources) =>
            resources[`${this.selectValue.sortName}`].match(this.searchInput) &&
            this.filterSite.includes(resources['county'])
        )
      }
      return this.rebuildAlarmsData.filter((resources) =>
        resources[`${this.selectValue.sortName}`].match(this.searchInput)
      )
    }
  },
  methods: {
    ...mapActions(['asyncInfo']),
    async getRecentAlarms () {
      this.loadingData = true
      this.rebuildAlarmsData = []
      const recentAlarms = await getHistoryAlarmsApi()
      if (recentAlarms?.data?.success) {
        this.recentAlarmsData = recentAlarms.data.data
        this.dataBuild()
      }
      this.loadingData = false
    },
    dataBuild () {
      let array = []
      for (let i = 0; i < this.recentAlarmsData.length; i++) {
        let differentArray = []
        this.recentAlarmsData[i].allAlarms.forEach((x) => {
          differentArray.push({
            inv: x.inv,
            alarmType: x.alarmType
          })
        })
        differentArray = this.dedup(differentArray)
        for (let j = 0; j < differentArray.length; j++) {
          const arr = this.recentAlarmsData[i].allAlarms.filter(
            (x) =>
              x.inv === differentArray[j].inv &&
              x.alarmType === differentArray[j].alarmType
          )
          arr.map((x) => {
            if (x.level === 2) x.alarmType = '發電數據異常'
            // TODO: 無發電數據 -> 發電數據異常，之後要改回來

            // switch (x.level) {
            //   case 2:
            //     x.alarmType = '發電數據異常'
            //     break
            //   case 3:
            //     x.alarmType = '部分通訊異常/裝置斷訊'
            //     break
            //   case 6:
            //     x.alarmType = '系統未連線'
            //     break
            //   case 7:
            //     x.alarmType = '系統短暫斷線'
            //     break
            //   default:
            //     x.alarmType = '-'
            //     break
            // }
          })

          const inv = this.invOrNot(differentArray[j].inv)

          array.push({
            which: inv,
            lastAlarmType: arr[arr.length - 1].alarmType,
            lastColorLabel: arr[arr.length - 1].colorLabel,
            time: DateTime.fromISO(arr[arr.length - 1].timestamp).toFormat(
              'yyyy-MM-dd HH:mm:ss'
            ),
            factoryName: this.recentAlarmsData[i].factoryName,
            county: this.recentAlarmsData[i].county,
            maintainer: this.recentAlarmsData[i].maintainer,
            allAlarms: arr
          })
        }
      }
      this.rebuildAlarmsData = array
      this.sortHistoryData('lastAlarmType', 4)
    },
    // 重複的濾除
    dedup (arr) {
      let hashTable = {}
      return arr.filter(function (el) {
        let key = JSON.stringify(el)
        let match = Boolean(hashTable[key])
        let res = match ? false : (hashTable[key] = true)
        return res
      })
    },
    sortHistoryData (sortType, idx) {
      // if (sortType === 'lastAlarmType') {
      //   let sortData = this.rebuildAlarmsData.map((item) => {
      //     switch (item.lastAlarmType) {
      //       case '系統未連線':
      //         item.sortNo = 1
      //         break
      //       case '系統短暫斷線':
      //         item.sortNo = 2
      //         break
      //       case '發電數據異常':
      //         item.sortNo = 3
      //         break
      //       case '裝置斷訊':
      //         item.sortNo = 5
      //         break
      //       case '部分通訊異常':
      //         item.sortNo = 4
      //         break
      //       default:
      //         item.sortNo = 6
      //         break
      //     }
      //     return item
      //   })
      //   this.rebuildAlarmsData = sortData.sort((a, b) => a.sortNo - b.sortNo)
      // }
      this.rebuildAlarmsData = this.rebuildAlarmsData.sort((a, b) => {
        if (sortType === 'lastAlarmType') {
          const stringToOrder = (type) => {
            let order = 0
            switch (type) {
              case '系統未連線':
                order = 1
                break
              case '系統短暫斷線':
                order = 2
                break
              case '發電數據異常':
                order = 3
                break
              case '裝置斷訊':
                order = 4
                break
              case '部分通訊異常':
                order = 5
                break
              case '發電效率不佳':
                order = 6
                break
              default:
                order = 7
                break
            }
            return order
          }

          const aStr = stringToOrder(a[sortType])
          const bStr = stringToOrder(b[sortType])

          return aStr - bStr
        } else {
          return a[sortType] > b[sortType] ? 1 : -1
        }
      })
      if (!this.thTitle[idx].isSort) {
        this.rebuildAlarmsData = this.rebuildAlarmsData.reverse()
      }
      this.thTitle[idx].isSort = !this.thTitle[idx].isSort
    },
    levelBgColor (color) {
      // const width = 'width: 50px'
      if (color === 'red') {
        return `background-color: rgb(255,139,142);`
      } else if (color === 'orange') {
        return `background-color: orange;`
      } else {
        return `background-color: white;`
      }
    },
    getModalData (arr, factory) {
      this.modalData = arr
      this.modalTitle = factory
      this.$bvModal.show('settingModal')
    },
    changeTime (time) {
      return DateTime.fromISO(time).toFormat('yyyy-MM-dd HH:mm:ss')
    },
    invOrNot (device) {
      let inv =
        typeof device === 'number' ? 'Inv' + ('0' + device).slice(-2) : device
      if (device === null) inv = '系統斷線'
      return inv
    },
    openMap (factory) {
      this.$bvModal.show('mapModal')
      this.center = {}
      setTimeout(() => this.setCenter(factory), 400)
    },
    setCenter (factory) {
      const whichFactory = this.markers.find((x) => x.factoryName === factory)
      this.center = whichFactory.position
    },
    buildAlarmFactoryLng () {
      this.markers = []
      const filterAlarmFactory = this.factoryInfo
        .filter((x) => {
          return this.recentAlarmsData
            .map((y) => y.factoryName)
            .includes(x.factory)
        })
        .map((x) => {
          const thisFactory = this.recentAlarmsData.find(
            (y) => y.factoryName === x.factory
          )
          const backgroundColor = this.buildMapColor(thisFactory.allAlarms)
          return {
            factoryName: x.factory,
            position: {
              lng: x.informations.longitude,
              lat: x.informations.latitude
            },
            backgroundColor
          }
        })
      this.markers = filterAlarmFactory
    },
    buildMapColor (arr) {
      const colorLabels = ['red', 'yellow', 'blue', 'orange']
      const foundColor = arr.find((alarm) =>
        colorLabels.includes(alarm.colorLabel)
      )
      return foundColor ? this.levelBgColor(foundColor.colorLabel) : ''
    }
  },
  async created () {
    await Promise.all([this.getRecentAlarms(), this.asyncInfo()])
    await this.buildAlarmFactoryLng()
  }
}
</script>

<style lang="scss" scoped>
// cover google map css
:deep(.gm-style-iw-chr) {
  display: none;
}
:deep(.gm-style-iw-d) {
  height: 50px;
  margin: 0px -5px;
  div {
    font-weight: 700;
    font-size: 14px;
    padding-top: 14px;
    padding-left: 5px;
  }
  span {
    padding: 5px;
    // border-radius: 6px;
  }
}

table th {
  padding-left: 20px;
  padding-right: 20px;
}

table td {
  font-size: 17px;
  font-weight: 700;
  padding: 7px 15px;
}

.tableWrap {
  height: calc(100vh - 210px);
  overflow-x: auto;
  min-height: 450px;

  table {
    th {
      background-color: rgb(246, 247, 245);
    }
  }
  @media screen and (max-width: 575.98px) {
    height: calc(100vh - 288px);
  }
}
</style>
